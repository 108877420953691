<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import candidateCard from "../../../components/widgets/card/candidateCard.vue";
import { Erreur } from "../../../helpers/error";
import { Api } from "../../../helpers";
import loaderProcessVue from "../../../components/widgets/loaderProcess.vue";
import Swal from "sweetalert2";
/**
 * Projects-overview component
 */
export default {
    page: {
        title: "Projects Overview",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: {
        Layout, PageHeader,
        candidateCard,
        loaderProcessVue
    },
    data() {
        return {
            titre: "",
            progress: 0,
            process: false,
            addModal: false,
            candidateList: [],
            categories:[],
            candidateUser: {
                name: "",
                description: "",
                idCategorie:[],
            },
            time: "",
            title: "Liste des candidats",
            items: [
                {
                    text: "Compétiton",
                    href: "/award/awardList"
                },
                {
                    text: "Détails",
                    href: "/award/awardDetail/" + this.$route.params.id
                },
                {
                    text: "Candidats",
                    active: true
                }
            ]
        };
    },
    methods: {
        showAddModal() {
            this.addModal = true
        },

        imgVideoSelect(event) {
            this.imgSelect = event.target.files[0]; // Get the selected image or video
            console.log(this.imgSelect);
        },

        imgVideoSelectPaysage(event) {
            this.imgPaysage = event.target.files[0];
            console.log(this.imgPaysage);
        },

        createCandidate() {

            const onUploadProgress = progressEvent => {
                this.progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                console.log(`Upload progress: ${this.progress}%`);
            }

            this.process = true
            this.addModal = false
            
            this.titre = "Création de la compétition"
            Api.postFormData(`/voting/rest/candidat/create-candidat?categories=${this.candidateUser.idCategorie}&description=${this.candidateUser.description}&name=${this.candidateUser.name}`, {
                imagepaysage: this.imgPaysage,
                imageportrait: this.imgSelect,
            }, onUploadProgress)
                .then((response) => {
                    this.process = false
                    Swal.fire("Succes!", "Compétition créée avec succès", "success");
                    console.log(response);
                    // location.reload()
                }).catch((error) => {
                    this.process = false
                    console.log(error)
                    Erreur.gestionErreur(error.message)
                })
        },

    },
    mounted() {
        this.process = true
        this.titre = "Récupération des candidats"
        const onUploadProgress = (progressEvent) => {
            this.progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
            );
            console.log(`Upload progress: ${this.progress}%`);
        };
        Api.get(`/voting/rest/candidat/awards/${this.$route.params.id}`, { onUploadProgress })
            .then((res) => {
                this.process = false
                this.candidateList = res.data.content;
                console.log(this.candidateList);
            })
            .catch((error) => {
                this.process = false
                console.log(error);
                Erreur.gestionErreur(error.message);
            });

            Api.get(`/voting/rest/awards/${this.$route.params.id}`, { onUploadProgress })
            .then((res) => {
                this.process = false
                this.awardDetails = res.data.content;
                console.log(this.awardDetails);
                this.categories = this.awardDetails.categories
                console.log(this.award.categories)
            })
            .catch((error) => {
                this.process = false
                console.log(error);
                Erreur.gestionErreur(error.message);
            });
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <loaderProcessVue :title="titre" :progress="progress" :visible="process"></loaderProcessVue>

        <div style="display: flex; justify-content: space-between;">
            <div></div>
            <div>
                <button type="submit" @click="showAddModal" class="btn c2play-primary">
                    Ajouter
                </button>
            </div>
        </div>
        <br>
        <div class="row">
            <candidateCard v-for="candidate in candidateList" :key="candidate.id" :candidate="candidate"></candidateCard>
        </div>



        <b-modal v-model="addModal" id="modal-xl" size="xl" title="Nouveau candidat" title-class="font-18" hide-footer>
            <div class="card-body">
                <b-alert v-model="isAuthError" variant="danger" class="mt-1" dismissible>
                    {{ authError }}
                </b-alert>
                <form>
                    <div class="row">
                        <div class="form-group col-lg-12 mb-3">
                            <label for="projectname" class="col-form-label">Nom du candidat</label>
                            <div class="col-lg-12">
                                <input id="projectname" name="projectname" type="text" class="form-control"
                                    placeholder="Entrez le nom du candidat" v-model="candidateUser.name" />
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="form-group col-lg-6">
                            <label for="projectbudget" class="col-form-label">Image Portrait</label>
                            <div class="col-lg-12">
                                <input id="projectbudget" name="projectbudget" type="file" @change="imgVideoSelect($event)"
                                    class="form-control" accept=".png, .jpeg, .jpg" />
                            </div>
                        </div>

                        <div class="form-group col-lg-6">
                            <label for="projectbudget" class="col-form-label">Image plateau (Paysage)</label>
                            <div class="col-lg-12">
                                <input id="projectbudget" name="projectbudget" type="file"
                                    @change="imgVideoSelectPaysage($event)" class="form-control"
                                    accept=".png, .jpeg, .jpg" />
                            </div>
                        </div>
                    </div>

                    <div class="form-group row mb-4">
                        <label for="projectbudget" class="col-form-label">Description</label>

                        <div class="col-lg-12">
                            <textarea id="projectdesc" class="form-control" rows="3" v-model="candidateUser.description"
                                :placeholder="$t('pages.programme.formulaire.description.placeholder')"></textarea>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-3" v-for="categorie in categories" :key="categorie.id">
                            <b-form-checkbox v-model="candidateUser.idCategorie" name="checkbox-1" :value="categorie.id">
                                <div>
                                    {{ categorie.name }}
                                </div>
                            </b-form-checkbox>
                        </div>
                    </div>
                </form>
                <div class="row justify-content-end">
                    <div class="col-lg-10" style="margin-top: 10px">
                        <button type="submit" class="btn c2play-primary" style="position: relative; left: 40%"
                            @click="createCandidate">
                            {{ $t("addButton") }}
                        </button>
                    </div>
                </div>
            </div>
        </b-modal>
    </Layout>
</template>